import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/meddbtech.github.io/meddbtech.github.io/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "contact-us"
    }}>{`Contact us`}</h2>
    <h3 {...{
      "id": "meddbtech-co-ltd"
    }}>{`Meddbtech Co., Ltd.`}</h3>
    <ul>
      <li parentName="ul">{`Address: `}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "416px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.055555555555557%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABCUlEQVQY0zVQy0pDUQw8Fx8tdKcrRcSFbrT0FoulcLG25yQ590Efgm79/1+wU9BJrYshySSZPMIuxwJRzqHLHnJ3Ck19iPWxJCd6hpTD/q0qMF+cfL9/BSTp/SwybRM8B80FzNEWyNsQdvF1AK1rqK1hXYmUXphMyO2UzR9IdUSyKRvXxDWHebwlX+0rccFAQaINB0EKXLBowmlDSH7gViWLrmjHjEdsnjA/Iue5J/pjDppB6iGS3pG/JR4pWFJwECByQ2LDYmXxHGYrWENxE25OTpmzFtpwqNwzjkTHi7ipzmif2c+4+YStLv0nFPVf+E+U1oj66JOTf99tYqx/8LoDn49n+8mb8AseBaQLGol75gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f44cbf745374ab1d7a2b28825925280b/a3012/address.webp 360w", "/static/f44cbf745374ab1d7a2b28825925280b/da4cb/address.webp 416w"],
              "sizes": "(max-width: 416px) 100vw, 416px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f44cbf745374ab1d7a2b28825925280b/f21e7/address.png 360w", "/static/f44cbf745374ab1d7a2b28825925280b/b0122/address.png 416w"],
              "sizes": "(max-width: 416px) 100vw, 416px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f44cbf745374ab1d7a2b28825925280b/b0122/address.png",
              "alt": "address",
              "title": "address",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></li>
      <li parentName="ul">{`Phone: `}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "288px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "22.22222222222222%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAnklEQVQY042PsQ7CMAxEPRTxMQjoytY2dpKGAAUhUfj/r+AWLmUB0QFLJ1+c3IsswoJzgq6lOoHS+0xF+sB+fHvrBeHAmafnXJOgLTljPshXTcC24WVTQbVm+Azza+oO22/ZM+E3hLSCWiLwRCln1fSpzgFdV7QkcAdLV0JqPhwJ3DB8gcYHfPF+gEWee33qsKAIzDPAf1e2z5X1Z+UXfxptnjPYNkMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/cfa0ccb750ef6898083acc5b0222b306/c3a59/phone.webp 288w"],
              "sizes": "(max-width: 288px) 100vw, 288px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/cfa0ccb750ef6898083acc5b0222b306/477c9/phone.png 288w"],
              "sizes": "(max-width: 288px) 100vw, 288px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/cfa0ccb750ef6898083acc5b0222b306/477c9/phone.png",
              "alt": "phone",
              "title": "phone",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></li>
      <li parentName="ul">{`Email: `}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "288px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "22.22222222222222%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAArUlEQVQY06WPPQ7CMAyFI8QV4AYgEF2oGIElcdIfoIKKqrDA/a/wFr5KlRAzg2XnOe+z7RSCUyiJwskqIlGTUzPqvM2cPNlHp1ijxW9vyL4Y+8khhAniEmAmO+1kxUpW54rnQL3HtAa4BVYRDYNygBnmA8NneDcA6af5CDxOZWUDsMdcKaaOTVugHZ9btIuC9cDe8vZSLK4A7/RusrgjP+TLJ7DFsKn7Pbn8++QPRAeDWryC/BkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/314cf39917ec2865642a591d27eb87de/c3a59/email.webp 288w"],
              "sizes": "(max-width: 288px) 100vw, 288px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/314cf39917ec2865642a591d27eb87de/477c9/email.png 288w"],
              "sizes": "(max-width: 288px) 100vw, 288px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/314cf39917ec2865642a591d27eb87de/477c9/email.png",
              "alt": "email",
              "title": "email",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      